import React from 'react';
import Layout from '../components/layout';
import DayComponent from '../components/DayComponent';

const monthInText_arr = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

const Day = ({ pageContext }) => {
    const pageTitle = `${pageContext.kernel_name} for ${monthInText_arr[pageContext.monthDate-1]} ${pageContext.yearDate}`
    return (
        <Layout menuList={[pageContext.lang.name, pageContext.yearDate, pageContext.monthDate]} pageTitle={pageTitle} pageLang={pageContext.lang}>
            <DayComponent dayContext={pageContext.content}
            pageTitle={pageTitle}
            language={pageContext.lang.name}
            yearDate={pageContext.yearDate}
            monthDate={pageContext.monthDate} />
        </Layout>
    );
};

export default Day;

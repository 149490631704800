import React from 'react';
import { Link } from 'gatsby';

const yearBut = {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ddd',
    borderRadius: '5px',
    margin: '5px',
    padding: '5px',
    userSelect: 'none',
    color: '#000'
}

const monthInText_arr = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

const DayComponent = ({dayContext, pageTitle, language, yearDate, monthDate}) => {
    let num;
    if (monthDate[0] === '0') {
    num = monthDate[1]
    } else {
        num = monthDate
    }
  return (
    <div className={'row'}>
        <div className={'col-sm-12'}>
            <h1 style={{ marginTop: '30px' }}>{pageTitle}</h1>
        </div>
        {dayContext.map((elem) => {
            return (
                <div key={Date.now() + Math.random()} className="col-md-2 col-sm-6">
                    <Link style={{ textDecoration: 'none' }}
                    to={'/'+language+'/'+yearDate+'/'+monthDate+'/'+elem+'/'}>
                        <div style={yearBut}>{elem + ' ' + monthInText_arr[num - 1] + ' ' + yearDate}</div>
                    </Link>
                </div>
            )
        })}
    </div>
  )
}

export default DayComponent;